import _util from "util";
import _defineProperty from "define-property";
import _cacheBase from "cache-base";
import _componentEmitter from "component-emitter";
import _isobject from "isobject";
import _mixinDeep from "mixin-deep";
import _pascalcase from "pascalcase";
import _classUtils from "class-utils";
var exports = {};
var util = _util;
var define = _defineProperty;
var CacheBase = _cacheBase;
var Emitter = _componentEmitter;
var isObject = _isobject;
var merge = _mixinDeep;
var pascal = _pascalcase;
var cu = _classUtils;
/**
 * Optionally define a custom `cache` namespace to use.
 */

function namespace(name) {
  var Cache = name ? CacheBase.namespace(name) : CacheBase;
  var fns = [];
  /**
   * Create an instance of `Base` with the given `config` and `options`.
   *
   * ```js
   * // initialize with `config` and `options`
   * var app = new Base({isApp: true}, {abc: true});
   * app.set('foo', 'bar');
   *
   * // values defined with the given `config` object will be on the root of the instance
   * console.log(app.baz); //=> undefined
   * console.log(app.foo); //=> 'bar'
   * // or use `.get`
   * console.log(app.get('isApp')); //=> true
   * console.log(app.get('foo')); //=> 'bar'
   *
   * // values defined with the given `options` object will be on `app.options
   * console.log(app.options.abc); //=> true
   * ```
   *
   * @param {Object} `config` If supplied, this object is passed to [cache-base][] to merge onto the the instance upon instantiation.
   * @param {Object} `options` If supplied, this object is used to initialize the `base.options` object.
   * @api public
   */

  function Base(config, options) {
    if (!(this instanceof Base)) {
      return new Base(config, options);
    }

    Cache.call(this, config);
    this.is("base");
    this.initBase(config, options);
  }
  /**
   * Inherit cache-base
   */


  util.inherits(Base, Cache);
  /**
   * Add static emitter methods
   */

  Emitter(Base);
  /**
   * Initialize `Base` defaults with the given `config` object
   */

  Base.prototype.initBase = function (config, options) {
    this.options = merge({}, this.options, options);
    this.cache = this.cache || {};
    this.define("registered", {});
    if (name) this[name] = {}; // make `app._callbacks` non-enumerable

    this.define("_callbacks", this._callbacks);

    if (isObject(config)) {
      this.visit("set", config);
    }

    Base.run(this, "use", fns);
  };
  /**
   * Set the given `name` on `app._name` and `app.is*` properties. Used for doing
   * lookups in plugins.
   *
   * ```js
   * app.is('foo');
   * console.log(app._name);
   * //=> 'foo'
   * console.log(app.isFoo);
   * //=> true
   * app.is('bar');
   * console.log(app.isFoo);
   * //=> true
   * console.log(app.isBar);
   * //=> true
   * console.log(app._name);
   * //=> 'bar'
   * ```
   * @name .is
   * @param {String} `name`
   * @return {Boolean}
   * @api public
   */


  Base.prototype.is = function (name) {
    if (typeof name !== "string") {
      throw new TypeError("expected name to be a string");
    }

    this.define("is" + pascal(name), true);
    this.define("_name", name);
    this.define("_appname", name);
    return this;
  };
  /**
   * Returns true if a plugin has already been registered on an instance.
   *
   * Plugin implementors are encouraged to use this first thing in a plugin
   * to prevent the plugin from being called more than once on the same
   * instance.
   *
   * ```js
   * var base = new Base();
   * base.use(function(app) {
   *   if (app.isRegistered('myPlugin')) return;
   *   // do stuff to `app`
   * });
   *
   * // to also record the plugin as being registered
   * base.use(function(app) {
   *   if (app.isRegistered('myPlugin', true)) return;
   *   // do stuff to `app`
   * });
   * ```
   * @name .isRegistered
   * @emits `plugin` Emits the name of the plugin being registered. Useful for unit tests, to ensure plugins are only registered once.
   * @param {String} `name` The plugin name.
   * @param {Boolean} `register` If the plugin if not already registered, to record it as being registered pass `true` as the second argument.
   * @return {Boolean} Returns true if a plugin is already registered.
   * @api public
   */


  Base.prototype.isRegistered = function (name, register) {
    if (this.registered.hasOwnProperty(name)) {
      return true;
    }

    if (register !== false) {
      this.registered[name] = true;
      this.emit("plugin", name);
    }

    return false;
  };
  /**
   * Define a plugin function to be called immediately upon init. Plugins are chainable
   * and expose the following arguments to the plugin function:
   *
   * - `app`: the current instance of `Base`
   * - `base`: the [first ancestor instance](#base) of `Base`
   *
   * ```js
   * var app = new Base()
   *   .use(foo)
   *   .use(bar)
   *   .use(baz)
   * ```
   * @name .use
   * @param {Function} `fn` plugin function to call
   * @return {Object} Returns the item instance for chaining.
   * @api public
   */


  Base.prototype.use = function (fn) {
    fn.call(this, this);
    return this;
  };
  /**
   * The `.define` method is used for adding non-enumerable property on the instance.
   * Dot-notation is **not supported** with `define`.
   *
   * ```js
   * // arbitrary `render` function using lodash `template`
   * app.define('render', function(str, locals) {
   *   return _.template(str)(locals);
   * });
   * ```
   * @name .define
   * @param {String} `key` The name of the property to define.
   * @param {any} `value`
   * @return {Object} Returns the instance for chaining.
   * @api public
   */


  Base.prototype.define = function (key, val) {
    if (isObject(key)) {
      return this.visit("define", key);
    }

    define(this, key, val);
    return this;
  };
  /**
   * Mix property `key` onto the Base prototype. If base is inherited using
   * `Base.extend` this method will be overridden by a new `mixin` method that will
   * only add properties to the prototype of the inheriting application.
   *
   * ```js
   * app.mixin('foo', function() {
   *   // do stuff
   * });
   * ```
   * @name .mixin
   * @param {String} `key`
   * @param {Object|Array} `val`
   * @return {Object} Returns the `base` instance for chaining.
   * @api public
   */


  Base.prototype.mixin = function (key, val) {
    Base.prototype[key] = val;
    return this;
  };
  /**
   * Non-enumberable mixin array, used by the static [Base.mixin]() method.
   */


  Base.prototype.mixins = Base.prototype.mixins || [];
  /**
   * Getter/setter used when creating nested instances of `Base`, for storing a reference
   * to the first ancestor instance. This works by setting an instance of `Base` on the `parent`
   * property of a "child" instance. The `base` property defaults to the current instance if
   * no `parent` property is defined.
   *
   * ```js
   * // create an instance of `Base`, this is our first ("base") instance
   * var first = new Base();
   * first.foo = 'bar'; // arbitrary property, to make it easier to see what's happening later
   *
   * // create another instance
   * var second = new Base();
   * // create a reference to the first instance (`first`)
   * second.parent = first;
   *
   * // create another instance
   * var third = new Base();
   * // create a reference to the previous instance (`second`)
   * // repeat this pattern every time a "child" instance is created
   * third.parent = second;
   *
   * // we can always access the first instance using the `base` property
   * console.log(first.base.foo);
   * //=> 'bar'
   * console.log(second.base.foo);
   * //=> 'bar'
   * console.log(third.base.foo);
   * //=> 'bar'
   * // and now you know how to get to third base ;)
   * ```
   * @name .base
   * @api public
   */

  Object.defineProperty(Base.prototype, "base", {
    configurable: true,
    get: function () {
      return this.parent ? this.parent.base : this;
    }
  });
  /**
   * Static method for adding global plugin functions that will
   * be added to an instance when created.
   *
   * ```js
   * Base.use(function(app) {
   *   app.foo = 'bar';
   * });
   * var app = new Base();
   * console.log(app.foo);
   * //=> 'bar'
   * ```
   * @name #use
   * @param {Function} `fn` Plugin function to use on each instance.
   * @return {Object} Returns the `Base` constructor for chaining
   * @api public
   */

  define(Base, "use", function (fn) {
    fns.push(fn);
    return Base;
  });
  /**
   * Run an array of functions by passing each function
   * to a method on the given object specified by the given property.
   *
   * @param  {Object} `obj` Object containing method to use.
   * @param  {String} `prop` Name of the method on the object to use.
   * @param  {Array} `arr` Array of functions to pass to the method.
   */

  define(Base, "run", function (obj, prop, arr) {
    var len = arr.length,
        i = 0;

    while (len--) {
      obj[prop](arr[i++]);
    }

    return Base;
  });
  /**
   * Static method for inheriting the prototype and static methods of the `Base` class.
   * This method greatly simplifies the process of creating inheritance-based applications.
   * See [static-extend][] for more details.
   *
   * ```js
   * var extend = cu.extend(Parent);
   * Parent.extend(Child);
   *
   * // optional methods
   * Parent.extend(Child, {
   *   foo: function() {},
   *   bar: function() {}
   * });
   * ```
   * @name #extend
   * @param {Function} `Ctor` constructor to extend
   * @param {Object} `methods` Optional prototype properties to mix in.
   * @return {Object} Returns the `Base` constructor for chaining
   * @api public
   */

  define(Base, "extend", cu.extend(Base, function (Ctor, Parent) {
    Ctor.prototype.mixins = Ctor.prototype.mixins || [];
    define(Ctor, "mixin", function (fn) {
      var mixin = fn(Ctor.prototype, Ctor);

      if (typeof mixin === "function") {
        Ctor.prototype.mixins.push(mixin);
      }

      return Ctor;
    });
    define(Ctor, "mixins", function (Child) {
      Base.run(Child, "mixin", Ctor.prototype.mixins);
      return Ctor;
    });

    Ctor.prototype.mixin = function (key, value) {
      Ctor.prototype[key] = value;
      return this;
    };

    return Base;
  }));
  /**
   * Used for adding methods to the `Base` prototype, and/or to the prototype of child instances.
   * When a mixin function returns a function, the returned function is pushed onto the `.mixins`
   * array, making it available to be used on inheriting classes whenever `Base.mixins()` is
   * called (e.g. `Base.mixins(Child)`).
   *
   * ```js
   * Base.mixin(function(proto) {
   *   proto.foo = function(msg) {
   *     return 'foo ' + msg;
   *   };
   * });
   * ```
   * @name #mixin
   * @param {Function} `fn` Function to call
   * @return {Object} Returns the `Base` constructor for chaining
   * @api public
   */

  define(Base, "mixin", function (fn) {
    var mixin = fn(Base.prototype, Base);

    if (typeof mixin === "function") {
      Base.prototype.mixins.push(mixin);
    }

    return Base;
  });
  /**
   * Static method for running global mixin functions against a child constructor.
   * Mixins must be registered before calling this method.
   *
   * ```js
   * Base.extend(Child);
   * Base.mixins(Child);
   * ```
   * @name #mixins
   * @param {Function} `Child` Constructor function of a child class
   * @return {Object} Returns the `Base` constructor for chaining
   * @api public
   */

  define(Base, "mixins", function (Child) {
    Base.run(Child, "mixin", Base.prototype.mixins);
    return Base;
  });
  /**
   * Similar to `util.inherit`, but copies all static properties, prototype properties, and
   * getters/setters from `Provider` to `Receiver`. See [class-utils][]{#inherit} for more details.
   *
   * ```js
   * Base.inherit(Foo, Bar);
   * ```
   * @name #inherit
   * @param {Function} `Receiver` Receiving (child) constructor
   * @param {Function} `Provider` Providing (parent) constructor
   * @return {Object} Returns the `Base` constructor for chaining
   * @api public
   */

  define(Base, "inherit", cu.inherit);
  define(Base, "bubble", cu.bubble);
  return Base;
}
/**
 * Expose `Base` with default settings
 */


exports = namespace();
/**
 * Allow users to define a namespace
 */

exports.namespace = namespace;
export default exports;
const _namespace = exports.namespace;
export { _namespace as namespace };